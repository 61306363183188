<template>
  <div class="teste pa-0">
    <v-slider
        class="pa-0"
        height= "20px"
        width="20px"
        readonly
        v-model="value"
        :step="value"
        :success="value == '100' ? true : false "
        thumb-label="always"
        color="info darken-3"
    >
    </v-slider>
  </div>
</template>
<script>
export default {
  name: 'progress-circular',
  computed: {
    percentage() {
      return this.$props.value !== null ? `${this.$props.value}%` : 'Carregando'
    },
    teste() {
      return 50
    },
  },
  props: {
    value: {
      require: true,
      type: Number,
    },
    labeProgress: {
      require: false,
      type: String,
    },
  },
}
</script>

<style >
.teste{
  height: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
</style>
